import React from 'react';
import {BaseButton} from 'components/Buttons';
import {useLimits} from 'contexts/LimitContext';
import {useSubscription} from 'contexts/SubscriptionContext';

function PrivateNavPlan() {
  const {limit, totalLimit, isReachedLimit, companyLimitReached} = useLimits();
  const {isFree, onUpgrade, isTrialDone} = useSubscription();

  const isNearLimit = (limit / totalLimit) * 100 <= 30;

  const enableUpgrade =
    (isFree && !isTrialDone) ||
    isNearLimit ||
    isReachedLimit ||
    companyLimitReached;

  if (!enableUpgrade) return null;

  const buttonLabel = (() => {
    // free user without free trial
    if (!isTrialDone && isFree) return 'Try Basic Plan for Free';

    return 'Upgrade Plan';
  })();

  return (
    <div className="d-none d-sm-block">
      <BaseButton
        onClick={onUpgrade}
        className="mr-3 rounded-sm"
        color="danger"
        outline
      >
        {buttonLabel}
      </BaseButton>
    </div>
  );
}

export default PrivateNavPlan;
