import React from 'react';
import {UncontrolledTooltip} from 'reactstrap';

import {IconFA} from 'components/Icons';
import {useLimits} from 'contexts/LimitContext';
import {getFormattedNumber} from 'utils/numbers';
import {LoadingText} from 'components/Loading';

function PrivateNavLimit() {
  const {limit, isLoading} = useLimits();

  return (
    <div className="mr-3 d-none d-xl-block">
      <span className="text-dark font-weight-bold mr-1">
        Available Credits:{' '}
        {isLoading ? <LoadingText isLoading /> : getFormattedNumber(limit)}
      </span>
      <IconFA name="info-circle" color="dark" id="limit-tooltip" />

      <UncontrolledTooltip fade={false} target="limit-tooltip">
        <small>
          Refers to the remaining credits available for consumption within your
          current plan.
        </small>
      </UncontrolledTooltip>
    </div>
  );
}

export default PrivateNavLimit;
