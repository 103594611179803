import styles from './styles.module.scss';

import React from 'react';
import dayjs from 'dayjs';
import {NavLink} from 'react-router-dom';
import {Row, Col} from 'reactstrap';

import {IconFA} from 'components/Icons';
import {AuthConsumer, useAuth} from 'contexts/AuthContext';
import {useSubscription} from 'contexts/SubscriptionContext';

const Copyright = () => {
  const year = dayjs().format('YYYY');

  return (
    <div className="text-center text-lg-left">
      Copyright &copy; {year} IAF CertSearch. All Right Reserved
    </div>
  );
};

const Terms = () => (
  <nav className="text-center text-md-left mb-3 mb-md-0">
    <NavLink to="/policies/terms" className="mr-3">
      Terms and Conditions
    </NavLink>
    <NavLink to="/policies/privacy" className="mr-3">
      Privacy Policy
    </NavLink>
    <NavLink to="/contact">Contact</NavLink>
  </nav>
);

const Socials = () => (
  <nav className="text-center text-md-left mb-3 mb-md-0">
    <a
      href="//www.youtube.com/user/IAFandILAC"
      target="_blank"
      rel="noopener noreferrer"
      title="Go to Youtube :: IAF CertSearch"
      className="mr-2"
    >
      <span className="sr-only">Youtube</span>
      <span className="fa-stack fa-lg">
        <IconFA name="circle" stack="2x" />
        <IconFA name="youtube" stack="1x" inverse />
      </span>
    </a>
    <a
      href="//twitter.com/IAF_Global"
      target="_blank"
      rel="noopener noreferrer"
      title="Go to Twitter :: IAF CertSearch"
      className="mr-2"
    >
      <span className="sr-only">Twitter</span>
      <span className="fa-stack fa-lg">
        <IconFA name="circle" stack="2x" />
        <IconFA name="twitter" stack="1x" inverse />
      </span>
    </a>
    <a
      href="//www.linkedin.com/company/international-accreditation-forum-inc/"
      target="_blank"
      rel="noopener noreferrer"
      title="Go to LinkedIn :: IAF CertSearch"
    >
      <span className="sr-only">LinkedIn</span>
      <span className="fa-stack fa-lg">
        <IconFA name="circle" stack="2x" />
        <IconFA name="linkedin" stack="1x" inverse />
      </span>
    </a>
  </nav>
);

const SiteMap = () => {
  const {isAuth, isFetchingSession} = useAuth();
  const {isFreeTrial} = useSubscription();

  return (
    <nav>
      <Row>
        <Col sm="12" md="6" lg="4">
          <ul className="mb-3 mb-md-0">
            <li>
              <AuthConsumer>
                {({isAuth}) => {
                  if (isAuth) {
                    return (
                      <NavLink to="/dashboard" exact>
                        Dashboard
                      </NavLink>
                    );
                  } else {
                    return (
                      <NavLink to="/" exact>
                        Home
                      </NavLink>
                    );
                  }
                }}
              </AuthConsumer>
            </li>
            <li>
              <NavLink to="/about/iaf-certsearch" exact>
                IAF CertSearch
              </NavLink>
            </li>
            <li>
              <NavLink to="/about/iaf">
                International Accreditation Forum
              </NavLink>
            </li>
          </ul>
        </Col>

        {/*
        <Col sm="12" md="6" lg="4">
          <ul className="mb-3 mb-md-0">
            <li>
              <NavLink to="/about/accreditation-bodies">
                Accreditation Bodies
              </NavLink>
            </li>
            <li>
              <NavLink to="/about/certification-bodies">
                Certification Bodies
              </NavLink>
            </li>
            <li>
              <NavLink to="/about/accredited-certifications">
                Accredited Certifications
              </NavLink>
            </li>
            <li>
              <NavLink to="/about/iso-standards">ISO Standard</NavLink>
            </li>
          </ul>
        </Col>
        */}

        <Col sm="12" md="6" lg="4">
          <ul className="mb-3 mb-md-0">
            <li>
              <NavLink to="/search/accreditation-bodies">
                Accreditation Bodies List
              </NavLink>
            </li>
            <li>
              <NavLink to="/search/certification-bodies">
                Certification Bodies List
              </NavLink>
            </li>
            {/*
            <li>
              <Link to="/about/qualitytrade">QualityTrade</Link>
            </li>
            */}
            <li>
              <NavLink to="/about/iaf-badge">IAF CertSearch Mark</NavLink>
            </li>
          </ul>
        </Col>

        <Col sm="12" md="6" lg="4">
          <ul>
            {/*<li>
              <NavLink to="/help">Help</NavLink>
            </li>*/}
            {!isFreeTrial && !isFetchingSession && (
              <li>
                <a
                  href={isAuth ? '/membership/plans' : '/plans'}
                  target={isAuth ? '_self' : '_blank'}
                  rel="noopener noreferrer"
                >
                  Plans
                </a>
              </li>
            )}
            <li>
              <a
                href="https://support.iafcertsearch.org/iaf-certsearch-support"
                target="_blank"
                rel="noopener noreferrer"
              >
                IAF CertSearch Support
              </a>
            </li>
            <li>
              <a
                href="https://iaf.nu/en/iaf-certsearch-monthly-webinars"
                target="_blank"
                rel="noopener noreferrer"
              >
                IAF CertSearch Monthly Webinars
              </a>
            </li>
            <li>
              <a
                href="https://support.iafcertsearch.org/iaf-certsearch-faq"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQ
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </nav>
  );
};

const PageFooter = () => {
  return (
    <footer className={styles['page__footer']}>
      <div className="container">
        <SiteMap />
        <hr />
        <Row className="align-items-center pb-3">
          <div className="col-sm-12 col-lg-auto order-1 order-lg-2">
            <Row className="align-items-center">
              <div className="col-sm-12 col-md col-lg-auto">
                <Terms />
              </div>
              <div className="col-sm-12 col-md-auto">
                <Socials />
              </div>
            </Row>
          </div>

          <div className="col order-2 order-lg-1">
            <Copyright />
          </div>
        </Row>

        <div className="text-center">
          Powered by{' '}
          <strong>
            <a
              href="https://group.qualitytrade.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              QualityTrade
            </a>
          </strong>
        </div>
      </div>
    </footer>
  );
};

export default PageFooter;
