import styles from './styles.module.scss';

import React from 'react';
import cx from 'classnames';
import {Helmet} from 'react-helmet';
import {isEmpty} from 'lodash/lang';
import {get} from 'lodash/object';

import {withPage} from 'contexts/PageContext';
import {withViewport} from 'contexts/ViewportContext';

import {LoadingPage} from 'components/Loading';
import PageSidebar from './PageSidebar';
import PageHeader from './PageHeader';
import PageFooter from './PageFooter';

import {VIEWPORT_WIDTHS} from 'configs';
class Page extends React.Component {
  get pageTitle() {
    return (
      <Helmet>
        <title>{this.props.title}</title>
      </Helmet>
    );
  }

  get pageHeader() {
    const {header} = this.props.page;

    return header && (header === true ? <PageHeader /> : header);
  }

  get pageCenter() {
    const {isLoading, header} = this.props.page;

    return (
      <div
        className={cx('col', {
          [styles['page--has-header']]: header,
        })}
        style={{overflow: 'hidden'}}
      >
        <div className={styles['page__center']}>
          {isLoading && <LoadingPage isLoading={isLoading} />}

          <div className={styles['page__main']}>{this.props.children}</div>

          {this.pageFooter}
        </div>
      </div>
    );
  }

  get pageLeft() {
    const {sidebar} = this.props.page;
    const {size = 'xs'} = this.props.viewport || {};

    if (isEmpty(get(sidebar, 'left'))) return null;

    const {showAt, width, component} = sidebar.left;
    const isMobile = VIEWPORT_WIDTHS[size] < VIEWPORT_WIDTHS[showAt];

    return (
      <div
        className={cx('col-auto', {
          [styles['page--has-header']]: isMobile,
        })}
      >
        <PageSidebar side="left" showAt={showAt} width={width}>
          {component}
        </PageSidebar>
      </div>
    );
  }

  get pageRight() {
    const {sidebar} = this.props.page;
    const {size = 'xs'} = this.props.viewport || {};

    if (isEmpty(get(sidebar, 'right'))) return null;

    const {showAt, width, component} = sidebar.right;
    const isMobile = VIEWPORT_WIDTHS[size] < VIEWPORT_WIDTHS[showAt];

    return (
      <div
        className={cx('col-auto', {
          [styles['page--has-header']]: isMobile,
        })}
      >
        <PageSidebar side="right" showAt={showAt} width={width}>
          {component}
        </PageSidebar>
      </div>
    );
  }

  get pageFooter() {
    const {footer} = this.props.page;

    return footer && <PageFooter />;
  }

  get pageBackdrop() {
    const {isOpenLeftSidebar, isOpenRightSidebar, setSidebar, backdrop} =
      this.props.page;

    if (!isOpenLeftSidebar && !isOpenRightSidebar) return null;

    if (!backdrop) return null;

    return (
      <div
        className={styles['page__backdrop']}
        onClick={() => setSidebar('both', false)}
      />
    );
  }

  render() {
    return (
      <div className={styles['page']}>
        {this.pageTitle}
        {this.pageHeader}

        <div className="row flex-xl-nowrap no-gutters">
          {this.pageLeft}
          {this.pageCenter}
          {this.pageRight}
        </div>

        {this.pageBackdrop}
      </div>
    );
  }
}

export default withViewport(withPage(Page));
