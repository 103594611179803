//import {keys, pick} from 'lodash/object';

import {pick} from 'lodash/object';

export const COMPANY_TYPE = {
    ab: 'ab',
    cb: 'cb',
    ce: 'ce',
    company: 'company',
    mncb: 'mncb',
  },
  COMPANY_TYPE_LABEL = {
    [COMPANY_TYPE.ab]: 'Accreditation Body',
    [COMPANY_TYPE.cb]: 'Certification Body',
    [COMPANY_TYPE.ce]: 'Certified Company',
    [COMPANY_TYPE.company]: 'Company',
    [COMPANY_TYPE.mncb]: 'MNCB',
  };

// History Type

export const HISTORY_TYPE = {
    certification: 'certification',
    company: 'company',
  },
  HISTORY_TYPE_LABEL = {
    [HISTORY_TYPE.certification]: 'Certification',
    [HISTORY_TYPE.company]: 'Company',
  };

export const NOTIFICATION_TYPE = {
  alert: 'alert',
  email: 'email',
  popup: 'popup',
};

export const NOTIFICATION_STATUS = {
  read: 'read',
  unread: 'unread',
};

// For AB and CB
// Filter by message type to identify which company is the sender or receiver

export const ENQUIRY_MESSAGE_TYPE = {
  received: 'Received',
  sent: 'Sent',
};

export const ENQUIRY_TYPE = {
  accreditationConfirmation: 'Accreditation Confirmation',
  certificationConfirmation: 'Certification Confirmation',
  accreditationEnquiry: 'Accreditation enquiry',
  requestQuotation: 'Request Quotation',
  support: 'Support',
  feedback: 'Feedback',
};

export const AB_ENQUIRY_TYPE = pick(ENQUIRY_TYPE, [
  'accreditationConfirmation',
  'certificationConfirmation',
  'accreditationEnquiry',
  'support',
  'feedback',
]);

export const CB_ENQUIRY_TYPE = pick(ENQUIRY_TYPE, [
  'certificationConfirmation',
  'requestQuotation',
  'support',
  'feedback',
]);

export const CE_ENQUIRY_TYPE = pick(ENQUIRY_TYPE, [
  'certificationConfirmation',
  'support',
  'feedback',
]);

export const SIGNATURE_TYPE = {
  type: 'type',
  draw: 'draw',
  image: 'image',
};

export const SIGNATURE_FONT_TYPE = {
  pinyon: 'pinyon',
  'great-vibes': 'great-vibes',
  'ms-madi': 'ms-madi',
};

export const SITE_TYPE = {
  main: 'Main Site',
  add: 'Additional Site',
};

export const ALL_CONFIDENTIAL_TYPE = {
  all: 'All Fields',
};

export const CERT_SITES_CONFIDENTIAL_KEY = {
  ce_name: 'ce_name',
  street: 'street',
  city: 'city',
  state: 'state',
  post_code: 'post_code',
  countries_id: 'countries_id',
  scope: 'scope',
  type: 'type',
};

export const CERT_SITES_CONFIDENTIAL_TYPE = {
  [CERT_SITES_CONFIDENTIAL_KEY.ce_name]: 'Certified Entity Name',
  [CERT_SITES_CONFIDENTIAL_KEY.street]: 'Bldg. No/Floor No./Street',
  [CERT_SITES_CONFIDENTIAL_KEY.city]: 'City',
  [CERT_SITES_CONFIDENTIAL_KEY.state]: 'State',
  [CERT_SITES_CONFIDENTIAL_KEY.post_code]: 'Post Code/Zipcode',
  [CERT_SITES_CONFIDENTIAL_KEY.countries_id]: 'Country',
  [CERT_SITES_CONFIDENTIAL_KEY.scope]: 'Scope',
  [CERT_SITES_CONFIDENTIAL_KEY.type]: 'Site type',
};

export const CERT_SITES_CONFIDENTIAL_TOOLTIP = {
  [CERT_SITES_CONFIDENTIAL_KEY.ce_name]: 'Company Name',
  [CERT_SITES_CONFIDENTIAL_KEY.street]: 'Street',
  [CERT_SITES_CONFIDENTIAL_KEY.city]: 'City',
  [CERT_SITES_CONFIDENTIAL_KEY.state]: 'State',
  [CERT_SITES_CONFIDENTIAL_KEY.post_code]: 'Post code',
  [CERT_SITES_CONFIDENTIAL_KEY.countries_id]: 'Country/Economy',
  [CERT_SITES_CONFIDENTIAL_KEY.scope]: 'Scope',
  [CERT_SITES_CONFIDENTIAL_KEY.type]: 'Site type',
};

export const CERT_CONFIDENTIAL_GROUP_TAGGING_KEY = {
  cert_all_sites: 'cert_all_sites',
  cert_sites: 'cert_sites',
  cert_add_sites: 'cert_add_sites',
  cert_st_sch: 'cert_st_sch',
  cert_sct: 'cert_sct',
};

export const CERT_CONFIDENTIAL_TYPE = {
  cert_number: 'cert_number',
  ...CERT_SITES_CONFIDENTIAL_TYPE,
};

export const SITES_CONFIDENTIAL_KEY = {
  cert_all_sites: 'cert_all_sites',
};

export const SECTORS_CONFIDENTIAL_KEY = {
  cert_sct: 'cert_sct',
};

export const SECTORS_CONFIDENTIAL_TYPE = {
  [SECTORS_CONFIDENTIAL_KEY['cert_sct']]: 'Technical Sectors',
};

export const SITES_CONFIDENTIAL_TYPE = {
  [SITES_CONFIDENTIAL_KEY['cert_all_sites']]: 'Certification Sites',
};

export const CERT_DETAILS_CONFIDENTIAL_KEY = {
  cert_number: 'cert_number',
  cert_st_sch: 'cert_st_sch',
};

export const CERT_DETAILS_CONFIDENTIAL_TYPE = {
  [CERT_DETAILS_CONFIDENTIAL_KEY['cert_number']]: 'Certificate Number',
  [CERT_DETAILS_CONFIDENTIAL_KEY['cert_st_sch']]: 'Standard and Scheme',
};

export const CERT_CONFIDENTIAL_TYPE_TOOLTIP = {
  [CERT_CONFIDENTIAL_GROUP_TAGGING_KEY.cert_st_sch]: 'Standard and Scheme',
  [CERT_CONFIDENTIAL_TYPE.cert_number]: 'Certificate Number',
};

export const CONFIDENTIAL_TYPE = {
  all: 'all',
  specified: 'specified',
  none: 'none',
};

export const CE_DETAILS_CONFIDENTIAL_KEY = {
  ce_name: 'ce_name',
  ce_trading_name: 'ce_trading_name',
  ce_en_name: 'ce_en_name',
  countries_id: 'countries_id',
};

export const CE_MAIN_SITE_CONFIDENTIAL_KEY = {
  countries_id: 'countries_id',
  ce_street: 'ce_street',
  ce_city: 'ce_city',
  ce_state: 'ce_state',
  ce_post_code: 'ce_post_code',
  ce_full_address: 'ce_full_address',
};

export const CE_DETAILS_CONFIDENTIAL_TYPE = {
  [CE_DETAILS_CONFIDENTIAL_KEY['ce_name']]: 'Certified Entity Name',
  [CE_DETAILS_CONFIDENTIAL_KEY['ce_trading_name']]: 'Company Trading Name',
  [CE_DETAILS_CONFIDENTIAL_KEY['ce_en_name']]: 'Company English Name',
};

export const CE_MAIN_SITE_CONFIDENTIAL_TYPE = {
  [CE_MAIN_SITE_CONFIDENTIAL_KEY['ce_street']]: 'Business Registered Street',
  [CE_MAIN_SITE_CONFIDENTIAL_KEY['ce_city']]: 'Business Registered City',
  [CE_MAIN_SITE_CONFIDENTIAL_KEY['ce_state']]: 'Business Registered State',
  [CE_MAIN_SITE_CONFIDENTIAL_KEY['ce_post_code']]:
    'Business Registered Zipcode/Postcode',
  [CE_MAIN_SITE_CONFIDENTIAL_KEY['countries_id']]:
    'Business Registered Country/Economy',
  [CE_MAIN_SITE_CONFIDENTIAL_KEY['ce_full_address']]:
    'Business Registered Full Address',
};

export const CE_MAIN_SITE_CONFIDENTIAL_TOOLTIP = {
  [CE_MAIN_SITE_CONFIDENTIAL_KEY['ce_street']]: 'Street',
  [CE_MAIN_SITE_CONFIDENTIAL_KEY['ce_city']]: 'City',
  [CE_MAIN_SITE_CONFIDENTIAL_KEY['ce_state']]: 'State',
  [CE_MAIN_SITE_CONFIDENTIAL_KEY['ce_post_code']]: 'Post Code/Zipcode',
  // [CE_MAIN_SITE_CONFIDENTIAL_KEY['countries_id']]: 'Country',
  [CE_MAIN_SITE_CONFIDENTIAL_KEY['ce_full_address']]: 'Full Address',
};

export const CE_CONFIDENTIAL_TYPE = {
  ...CE_DETAILS_CONFIDENTIAL_TYPE,
  ...CE_MAIN_SITE_CONFIDENTIAL_TYPE,
};

export const CERT_CONFIDENTIAL_GROUP_TAGGING_LABEL = {
  [CERT_CONFIDENTIAL_GROUP_TAGGING_KEY['cert_sites']]: 'Certification Sites',
  [CERT_CONFIDENTIAL_GROUP_TAGGING_KEY['cert_add_sites']]:
    'Certification Sites',
  [CERT_CONFIDENTIAL_GROUP_TAGGING_KEY['cert_st_sch']]: 'Standard and Schemes',
  [CERT_CONFIDENTIAL_GROUP_TAGGING_KEY['cert_sct']]: 'Technical Sectors',
};

export const COOKIE_CONSENT_TYPES = {
  necessary: 'necessary',
  statistics: 'statistics',
};
