import styles from './styles.module.scss';
import React from 'react';
import {withRouter} from 'react-router';
import {withAuth} from 'contexts/AuthContext';
import DataLayerPush from 'utils/gtm';

import {
  InputGroup,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  ButtonDropdown,
} from 'reactstrap';
import {CaptchaModal} from 'components/Captcha';
import {isEmpty} from 'lodash';
import {IconFA} from 'components/Icons';
import {SearchSelectCE} from 'components/Forms/Searches';
import {SearchSelectCert} from 'components/Forms/Searches';
import cx from 'classnames';

class PrivateNavSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,

      searchType: 'certified-entity',
      label: 'Company Name',
    };

    this.toggle = this.toggle.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  onSelectChange(event, trigger = true) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen && trigger,
      searchType: event.target.value,
      label: event.target.innerText,
      active: 'certified-entity',
    });
  }

  // close dropdown when mobile
  componentDidUpdate(prevProps) {
    const {isMobile} = this.props;

    if (isMobile !== prevProps.isMobile) {
      this.setState({
        dropdownOpen: false,
      });
    }
  }

  render() {
    const {location, isMobile, hasSidebar} = this.props;
    const {label, dropdownOpen, searchType} = this.state;
    const {eventSearchInput, eventSearchClick} = DataLayerPush;

    const {pathname} = location;

    const isCE = searchType === 'certified-entity';

    return (
      <CaptchaModal initialCheck={false}>
        {({verifyCaptcha}) => {
          return (
            <div
              className={cx(styles['search__forms'], {
                'mx-0': hasSidebar && !isMobile,
              })}
            >
              <InputGroup
                className={cx(
                  'onboard-search-verify',
                  styles['private-search__input-group']
                )}
              >
                {!isMobile && (
                  <ButtonDropdown isOpen={dropdownOpen} toggle={this.toggle}>
                    <DropdownToggle
                      className={styles['private-search__dropdown']}
                    >
                      <IconFA
                        className="text-primary mr-1"
                        name={isCE ? 'building' : 'file'}
                      />
                      <span className="d-sm-none d-xl-inline-block">
                        {label}
                      </span>
                      <IconFA className="ml-1" name="chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu className="mt-2 py-0" container="">
                      <DropdownItem
                        className={styles['private-search__dropdown--item']}
                        value="certified-entity"
                        onClick={(event) =>
                          this.onSelectChange(event, 'certified-entity')
                        }
                      >
                        <IconFA name="building" className="mr-1 text-primary" />
                        Company Name
                      </DropdownItem>
                      <DropdownItem
                        className={styles['private-search__dropdown--item']}
                        value="certification"
                        onClick={(event) =>
                          this.onSelectChange(event, 'certification')
                        }
                      >
                        <IconFA name="file" className="mr-1 text-primary" />
                        Certification ID
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                )}

                <div className="flex-grow-1" onClick={verifyCaptcha}>
                  {searchType === 'certified-entity' ? (
                    <SearchSelectCE
                      key={pathname}
                      dropdownOpen={dropdownOpen}
                      verifyCaptcha={verifyCaptcha}
                      onChangeType={this.onSelectChange}
                      className={styles['form-control-search']}
                      onInputChange={(input, result) => {
                        eventSearchInput({
                          searchInputEntityType: 'ce',
                          searchInputLocation: 'header',
                          searchInputKeyword: input,
                          searchInputResult: !isEmpty(result),
                        });
                      }}
                      onSelect={(selected, keyword) => {
                        const {company_id, company_name} = selected || {};

                        if (!isEmpty(selected)) {
                          eventSearchClick({
                            searchClickEntityType: 'ce',
                            searchClickLocation: 'header',
                            searchClickKeyword: keyword,
                            searchClickEntityName: company_name,
                            searchClickEntityId: company_id,
                          });
                        }
                      }}
                    />
                  ) : (
                    <SearchSelectCert
                      key={pathname}
                      dropdownOpen={dropdownOpen}
                      verifyCaptcha={verifyCaptcha}
                      onChangeType={this.onSelectChange}
                      className={styles['form-control-search']}
                      onInputChange={(input, result) => {
                        eventSearchInput({
                          searchInputEntityType: 'cert',
                          searchInputLocation: 'header',
                          searchInputKeyword: input,
                          searchInputResult: !isEmpty(result),
                        });
                      }}
                      onSelect={(selected, keyword) => {
                        const {company_certifications_id, company_name} =
                          selected || {};

                        if (!isEmpty(selected)) {
                          eventSearchClick({
                            searchClickEntityType: 'cert',
                            searchClickLocation: 'header',
                            searchClickKeyword: keyword,
                            searchClickEntityName: company_name,
                            searchClickEntityId: company_certifications_id,
                          });
                        }
                      }}
                    />
                  )}
                </div>
              </InputGroup>
            </div>
          );
        }}
      </CaptchaModal>
    );
  }
}

export default withRouter(withAuth(PrivateNavSearch));
